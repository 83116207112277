


































































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { getAsBase64, photoService } from "@/services/photo.service";
import { photoMapper } from "@/mappers/photo.mapper";
import { ClassificationTask } from "@/models/ClassificationTask";
import { taskService } from "@/services/task.service";
import { taskMapper } from "@/mappers/task.mapper";
import { ZoomManager } from "@/models/ZoomManager";
import { ImageData } from "@/models/ImageData";
import { QuestionChoiceEntity } from "@/entities/QuestionChoice.entity";
import { QuestionEntity } from "@/entities/Question.entity";
import { questionService } from "@/services/question.service";
import { QuestionAnswerEntity } from "@/entities/QuestionAnswer.entity";

interface StageConfig {
  width: number;
  height: number;
}

const width = window.innerWidth;
const height = window.innerHeight;

@Component({
  components: {
    Thumbnails: () => import("@/components/Thumbnails.vue")
  }
})
export default class Classify extends Vue {
  currentImgSrc = '';
  imageData = new ImageData([], [], 0, new window.Image(), 1);
  zoomManager: ZoomManager = new ZoomManager();

  stageConfig: StageConfig = {
    width: width,
    height: height
  };

  thumbnailsHeightPrefixSum: number[] = [];
  next = "next";

  task = new ClassificationTask(0, "", "", 0, new QuestionEntity(0, ""), []);
  taskId = 0;
  photoCollectionId = 0;
  wasAnswered = false;
  prevAnswer = new QuestionAnswerEntity(0, 0, 0, 0, "");
  selectedChoice = new QuestionChoiceEntity(0, "", 0);
  hotkeyDict = { left: ["arrowleft"], right: ["arrowright"], a: ["a"] };
  infoVisible = false;
  instructions = [
    "To zoom in/out -> ctrl + mousewheel",
    "Two modes available: Normal and Move",
    "Move mode allows you to move on zoomed-in images",
    "You can select an answer with number keys (1-9)"
  ];
  comment = "";
  loading = false;
  loadingComment = false;
  loadingImage = false;

  get zoomValue() {
    return this.zoomManager.zoomValue;
  }
  get imageOffset() {
    return this.zoomManager.imageOffset;
  }
  get cursorStyle() {
    return this.zoomManager.cursorStyle;
  }
  get isZoomNormalMode() {
    return this.zoomManager.isZoomNormalMode();
  }
  get currentZoomMode() {
    return this.isZoomNormalMode ? "Normal" : "Move";
  }

  mounted() {
    this.imageHandler();
  }

  @Watch("imageData.imageScale", { immediate: true })
  imageScaleHandler() {
    this.zoomManager.imageScale = this.imageData.imageScale;
  }

  get imageIndex() {
    return this.imageData.currentImageIndex;
  }
  get imageId() {
    if (this.imageData.images.length == 0) {
      return 0;
    }
    return this.imageData.images[this.imageIndex].id;
  }

  getAnswerForPhoto() {
    this.selectedChoice = new QuestionChoiceEntity(0, "", 0);
    this.wasAnswered = false;
    this.prevAnswer = new QuestionAnswerEntity(0, 0, 0, 0, "");

    questionService
      .getAnswerForTaskAndPhoto(this.task.id, this.imageId)
      .then(response => {
        this.wasAnswered = true;

        this.prevAnswer = response.data;
        this.selectedChoice = this.task.choices.filter(
          choice => choice.id == this.prevAnswer.choice
        )[0];
      })
      .catch(() => {
        // that means the question wasn't answered yet
        this.wasAnswered = false;
      });
  }

  // Watch
  @Watch("imageData.currentWindowImage", { deep: true })
  imageHandler(): void {
    this.zoomManager.resetZoom();

    if (this.imageId != 0) {
      this.getAnswerForPhoto();
      this.stageConfig = {
        width: this.imageData.currentWindowImage.width,
        height: this.imageData.currentWindowImage.height
      };
      this.zoomManager.imageSize = {
        width: this.imageData.currentWindowImage.width,
        height: this.imageData.currentWindowImage.height
      };
      this.zoomManager.adjustMaxZoom();
    }
  }

  get isLoading() {
    return this.loading || this.loadingComment || this.loadingImage;
  }

  async created() {
    this.getDataFromRouteQuery();
    await this.getTask();
    this.getPhotosForPhotoCollection(this.photoCollectionId);
    this.getThumbnailsForPhotoCollection(this.photoCollectionId);

    // set up categories hotkeys
    for (let i = 1; i <= 9; i++) {
      this.hotkeyDict = { ...this.hotkeyDict, ...{ [String(i)]: [String(i)] } };
    }
  }

  private isValidURL(str: string) {
    let a = document.createElement("a");
    a.href = str;
    return a.host && a.host != window.location.host;
  }

  private getDataFromRouteQuery() {
    this.taskId = Number(this.$route.query.taskId as string);
    this.photoCollectionId = Number(
      this.$route.query.photoCollectionId as string
    );
  }

  private async getTask() {
    await taskService.getClassificationTask(this.taskId).then(response => {
      console.log(response.data);
      this.task = taskMapper.mapClassificationTask(response.data);
    });
  }

  private getPhotosForPhotoCollection(photoCollectionId: number) {
    photoService
      .getPhotosForPhotoCollection(photoCollectionId)
      .then(response => {
        this.imageData.images = photoMapper.mapPhotos(response.data);
        this.setImage();
      });
  }

  private getThumbnailsForPhotoCollection(photoCollectionId: number) {
    photoService
      .getThumbnailsForPhotoCollection(photoCollectionId)
      .then(async response => {
        const thumbnailPhotos = photoMapper.mapPhotos(response.data);
        Vue.set(this.imageData, "thumbnailsData", thumbnailPhotos);
        photoService
          .getThumbnailsHeightPrefixSum(photoCollectionId)
          .then(response => {
            this.thumbnailsHeightPrefixSum = response.data;
          });
      });
  }

  previousImage(): void {
    this.prevImageSave();
  }

  changeButtonNames(): void {
    if (this.imageData.currentImageIndex >= this.imageData.images.length - 2) {
      this.next = "end";
    }
  }

  nextImage(): void {
    this.nextImageSave();
  }

  prevImageSave() {
    if (this.isLoading) {
      return;
    }
    if (this.imageData.currentImageIndex > 0) {
      if (this.next === "end") {
        this.next = "next";
      }
      this.loading = true;
      this.save()
        .then(() => {
          this.imageData.currentImageIndex--;
          this.setImage();
          this.comment = "";
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.save();
      window.location.href = "/tasks";
    }
  }

  nextImageSave(): void {
    if (this.isLoading) {
      return;
    }
    if (this.imageData.currentImageIndex + 1 < this.imageData.images.length) {
      this.loading = true;
      this.changeButtonNames();
      this.save()
        .then(() => {
          this.imageData.currentImageIndex++;
          this.setImage();
          this.comment = "";
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.save();
      window.location.href = "/tasks";
    }
  }

  setComment() {
    this.loadingComment = true;
    questionService
      .getAnswerForTaskAndPhoto(
        this.task.id,
        this.imageId
      )
      .then(response => {
        this.comment = response.data["comment"];
      })
      .catch(() => {
        this.comment = "";
      })
      .finally(() => {
        this.loadingComment = false;
      });
  }

  setImage() {
    this.setComment();

    const img = new window.Image();
    const base64 = getAsBase64(
      this.imageData.images[this.imageData.currentImageIndex].imageSrc
    );

    this.loadingImage = true;
    base64.then(base64Src => {
      if (typeof base64Src === "string") {
        img.src = this.currentImgSrc = base64Src;

        img.onload = () => {
          // width adequately to the number of columns
          const maxWidth = (window.innerWidth * 7) / 12;
          const maxHeight = window.innerHeight - 4 * 60;

          const scale = Math.min(maxWidth / img.width, maxHeight / img.height);
          img.height *= scale;
          img.width *= scale;

          this.imageData.currentWindowImage = img;
          this.imageData.imageScale = scale;
          this.loadingImage = false;
        };
      }
    });
  }

  handleThumbnailClick(index: number) {
    if (
      index >= 0 &&
      index < this.imageData.images.length &&
      index != this.imageData.currentImageIndex &&
      !this.isLoading
    ) {
      this.imageData.currentImageIndex = index;
      this.setImage();
    }
  }

  selectChoice(choice: QuestionChoiceEntity) {
    this.selectedChoice = choice;
  }

  async save() {
    if (this.selectedChoice.id == 0) {
      // no selection
      return;
    }

    if (this.wasAnswered && this.selectedChoice.id == this.prevAnswer.choice && this.comment == this.prevAnswer.comment) {
      // answer hasn't changed
      return;
    }

    if (this.wasAnswered) {
      const newAnswer = new QuestionAnswerEntity(
        this.prevAnswer.id,
        this.prevAnswer.task,
        this.prevAnswer.photo,
        this.selectedChoice.id,
        this.comment
      );

      await questionService.updateAnswer(newAnswer);
    } else {
      await questionService.addAnswer(
        this.task.id,
        this.imageId,
        this.selectedChoice.id,
        this.comment
      );
    }
  }

  hotkeyHandler(event: any) {
    switch (event.srcKey) {
      case "left":
        this.previousImage();
        break;
      case "right":
        this.nextImage();
        break;
      case "a":
        this.nextImageSave();
        break;
    }
    if (event.srcKey >= "1" && event.srcKey <= "9") {
      const index = event.srcKey - Number("0") - 1;
      if (index >= 0 && index < this.task.choices.length) {
        this.selectChoice(this.task.choices[index]);
      }
    }
  }
}
